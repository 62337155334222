.fit_parent {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.nopadding {
  padding: 0.6rem;
}

.nomargin {
  margin: 0 auto;
}
.text_settings {
  text-align: center;
  vertical-align: middle;
}

.mainsettings_row {
  margin-bottom: 0.5rem;
  height: 2.6rem;
}
.setting_selected {
  background-color: #007bff;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

.border_separation {
  border-bottom: 3px ridge rgba(146, 208, 80, 1);
  text-align: center;
}
