@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./layout/navigation.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/loading.scss";
@import "additional-styles/flatpickr.scss";

@responsive {
  .rtl {
    direction: rtl;
  }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 10px;
@import "node_modules/aos/src/sass/aos.scss";
