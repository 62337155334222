.calendar_display{
    /*Controls how close each calendar is to eachother*/
    padding-left: 0px;
    padding-right: 0px;
}

.textsizelbl{
    font-size: 12px;
}

.individualdatedisp{
    max-height: 30px;
}

.rateDNE{
    color:red;
}
