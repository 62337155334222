.navi {
  &__checkbox {
    display: none;
  }
  &__button {
    background-color: white;
    height: 4rem;
    width: 4rem;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    border-radius: 50%;
    z-index: 102;
    text-align: center;
    box-shadow: 0px 0px 5px 5px #6f7274;

    cursor: pointer;
  }
  &__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    visibility: hidden;
    opacity: 0;
    width: 0;
    transition: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  &__icon-position {
    border: 1px black;
    position: relative;
    //bottom: 1.5rem;
    right: -0.7rem;
    top: 0.9rem;
  }
  &__icon {
    fill: #5eb903; //#0766BD;
    height: 2.5rem;
    width: 2.5rem;
  }
  &__list {
    position: relative;
    list-style: none;
    text-align: center;
    background-color: white;
    box-shadow: 0px 0px 5px 5px #6f7274;
    width: 14rem;
    position: fixed;
    bottom: 6.5rem;
    right: 1.5rem;
    border-radius: 0.5rem;
  }
  &__item {
    margin: 1rem;
  }
  &__link {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 700;
    color: #0766bd;
    text-decoration: none;
    text-transform: uppercase;
  }
  &__link {
    &:link,
    &:visited {
      display: flex;
      align-items: center;
      right: 2rem;
      font-size: 1rem;
      font-weight: 700;
      color: #0766bd;
      text-decoration: none;
      text-transform: uppercase;
      /* background-image: linear-gradient(105deg, transparent 50%, transparent 50%, rgba($color-white, 1) 50%);
			background-size: 210%;
            transition: all .2s; */
      span {
        margin-right: 1rem;
        display: inline-block;
      }
    }
  }
  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
    visibility: visible;
  }
}

.scrollbar-blue-500 {
  scrollbar-color: #4285f4 #f5f5f5;
}
