.bar_height {
  height: 50vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
}
.container-chart {
  height: 85%;
}
.container-chart-height {
  height: 100%;
}
