.outline {
  outline-style: auto;
}
.container-maxw {
  max-width: 100%;
  padding-left: 5px;
}
#maingrid {
  padding: 0px;
}
.rowClassRule {
  background-color: orangered !important;
}
.cellCI {
  background-color: orangered !important;
}
.cellCI_Selected {
  background-color: blue !important;
}
.celltest {
  background-color: rgba(146, 208, 80, 0.9) !important;
  border-block-end: black !important;
}
.cellreservation {
  background-color: rgb(251, 255, 0) !important;
  border-block-end: black !important;
}
.cellcanShift {
  background-color: rgb(0, 255, 0) !important;
  border-block-end: black !important;
}
.date_controller {
  padding: 5px;
}

.separationclass {
  box-shadow: 0px 30px 3px -26px rgba(146, 208, 80, 1);
}
.previewgridcol {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding_view {
  padding-top: 20px;
  padding-bottom: 20px;
}
.individual_buttonbar {
  padding-right: 50px;
}
.ag-theme-balham .ag-cell-data-changed {
  background-color: rgb(255, 0, 0) !important;
}
.ag-theme-balham .ag-cell-data-changed-animation {
  background-color: transparent;
  transition: background-color 5s !important;
}

.btn-small {
  width: 19px;
  height: 20px;
  margin-top: 0em;
  border-radius: 1000px;
  padding: 0rem 0rem 0rem 0rem;
}
.row_end {
  text-align: end;
}

.btnbar {
  margin-right: 0.5em;
}
@media (max-width: 1400px) {
  .btnbar {
    font-size: 0.8rem;
    padding: 2px;
    border-width: 2px;
  }
}

.grid_height_left_ctr {
  height: 50vh;
}

.main_grid_height_ctr {
  height: 85vh;
  width: 100%;
}

@media (max-width: 1700px) {
  .price_text_size {
    font-size: 0.8rem;
  }
}
