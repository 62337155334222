.main_Jumbo-Container {
  height: 70vh;
}
.box-shadow-main1 {
  border-radius: 0.5rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.container-Width {
  background-color: rgba(0, 0, 0, 0.045);

  padding: 1rem 1rem;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.image_logo--container {
  background-color: rgba(0, 0, 0, 0.045);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  max-width: 120px;
  padding: 0.3rem 0.3rem;
  transform: translate(90vw, 0);
}

.image_logo {
  width: 90%;
  display: block;
  margin: 0 auto;
}

.Return_Main-btn {
  text-align: end;
  padding-bottom: 10px;
}

.info_paragraph {
  width: 90%;
  margin: 0 auto;
}

.center_progress {
  transform: translate(0, 50%);
}

.TermsandConditions_box {
  margin: 0 auto;
  width: 65%;
}
.TermsandConditions_sizing {
  margin: 10px auto;
  width: 80%;
}
.FormBox_Sizing {
  margin: 0 auto;
  width: 95%;
}

.Error_Paragraph {
  color: red;
}
.Adding_Discount-btn {
  text-align: end;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.information_text-separation {
  padding-bottom: 10px;
}
.RoomBox_style {
  padding-top: 10px;
}
.Form_Box-separation {
  padding-top: 15px;
}
.pagination_main {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.separationclass--small {
  box-shadow: 0px 10px 3px -5px rgba(146, 208, 80, 1);
}
.Add_Photos-btn--align {
  text-align: end;
}
.image_room--container {
  background-color: rgba(0, 0, 0, 0.045);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  max-width: 120px;
  padding: 0.3rem 0.3rem;
}
.form-group {
  margin-bottom: 0.1rem !important;
}
.room_img_preview--container {
  width: 230px;
  padding-bottom: 5px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.innerpadding-col {
  padding: 5px;
}
.imgscaling {
  width: 100%;
}
.gallery-container {
  height: 37vh;
}
.maingallery {
  height: 100%;
}
.scrollbar {
  overflow-y: scroll;
}
.scrollbar-primary {
  scrollbar-color: #4285f4 #f5f5f5;
}
.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285f4;
}
.nooverflow {
  overflow: hidden;
}
@media (max-width: 1200px) {
  .gallery-container {
    max-height: 35vh;
  }
  .maingallery {
    height: 100%;
  }
}
@media (max-width: 992px) {
  .gallery-container {
    height: 32vh;
  }
  .maingallery {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .gallery-container {
    height: 30vh;
  }
  .maingallery {
    height: 100%;
  }
}
@media (max-width: 576px) {
  .gallery-container {
    height: 30vh;
  }
  .maingallery {
    height: 100%;
  }
}
@media (max-width: 520px) {
  .gallery-container {
    height: 28vh;
  }
  .maingallery {
    height: 100%;
  }
}
.txtred {
  color: red;
  text-align: center;
  transform: translateY(100%);
}
.stripebtn {
  padding-top: 10px;
  text-align: center;
}
.stripebtn-link > * {
  transition: all 0.2s ease-in-out;
}

.stripebtn-link:hover > * {
  cursor: pointer;
  transform: scale(1.1);
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.str_disabled {
  pointer-events: none;
  cursor: default;
}

.loading {
  /* draws the circle*/
  width: 50px;
  height: 50px;
  border: 5px solid rgb(66, 100, 255);
  border-top-color: rgba(255, 255, 255, 0.753);
  border-radius: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 2;
  animation: round 2s linear infinite;
}
@keyframes round {
  /* this controls the loading part spinner tells where to go to */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingmsg {
  /* this controls the loading part spinner*/
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 2;
  max-width: 300px;
  max-height: 300px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
}

.popup {
  /* this controls the loading part text*/
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.753);
  width: 100%;
  height: 100%;
}

.loadingtext {
  position: relative;
  top: 60%;
}
.anchorStripe {
  text-decoration: none;
  color: #007bff;
}
.anchorStripe:hover {
  color: #0056b3;
  cursor: pointer;
}
