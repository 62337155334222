.loading {
  /* draws the circle*/
  width: 50px;
  height: 50px;
  border: 5px solid rgb(66, 100, 255);
  border-top-color: rgba(255, 255, 255, 0.753);
  border-radius: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 2;
  animation: round 2s linear infinite;
}

input:checked ~ .dot {
  transform: translateX(100%);
}
input:checked ~ .bg-swith {
  background-color: #48bb78 !important;
}

@keyframes round {
  /* this controls the loading part spinner tells where to go to */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
